import { Example } from "./Example";
import { useState } from "react";
import styles from "./Example.module.css";
import { questionConfig } from "../../api";


export type ExampleModel = {
    text: string;
    value: string;
};


interface Props {
    onExampleClicked: (value: string) => void;
}


export const ExampleList = ({ onExampleClicked }: Props) => {
    const [question1, setQuestion1] = useState<string>("");
    const [question2, setQuestion2] = useState<string>("");    

    const getQuestions = async () => {
        const defaultQuestion1 = 'What can you tell me about the project?';
        const defaultQuestion2 = 'What are the deliverables of the project?';

        const questionResponse = await questionConfig();
        // console.log(questionResponse)
        
        setQuestion1(questionResponse.question_1 || defaultQuestion1);
        setQuestion2(questionResponse.question_2 || defaultQuestion2);
    };
    getQuestions();

    const EXAMPLES: ExampleModel[] = [
        { text: question1, value: question1 },
        { text: question2, value: question2 }, 
    ];

    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
