import { ReactNode, useState } from "react";
import Chat from "../chat/Chat";
import { ThemeProvider } from "../../context/theme";
import styles from "./Layout.module.css";
import { useMsal, useMsalAuthentication, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { ArcContainer } from "@arc-web/components/dist/components/container/react";
import { useThemeContext } from "../../context/theme";
import { Theme, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isAuthenticated } from "../../lib/auth";
import SignIn from "../signin/SignIn";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import React from "react";
import CsvTable from "../tableviewer/csv-table.component";

  
const Layout = () => {
    const [isAuth, setIsAuth] = useState(isAuthenticated());
    return (
        <>
            
            {isAuth ? 
                <Router>
                    <Routes>
                        <Route path="/" element={<Chat />} /> 
                        <Route path="/knowledgebase" element={<CsvTable theme={undefined} />} /> 
                    </Routes>
                </Router> : <SignIn setIsAuth={setIsAuth} /> }

        </>
    );
};

export default Layout;
