import { AskRequest, AskResponse, ChatRequest } from "./models";

interface questionResponse {
    question_1?: string;
    question_2?: string;
    chatbox_text?: string;
    landing_text?: string;
}
interface knowledgeTable {
    
}

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL

// export async function askApi(options: AskRequest): Promise<AskResponse> {
//     const idToken =  localStorage.getItem("idToken")
//     const response = await fetch(apiBaseUrl+"/ask", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": "Bearer "+idToken
//         },
//         body: JSON.stringify({
//             question: options.question,
//             approach: options.approach,
//             overrides: {
//                 retrieval_mode: options.overrides?.retrievalMode,
//                 semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory
//             }
//         })
//     });

//     const parsedResponse: AskResponse = await response.json();
//     if (response.status > 299 || !response.ok) {
//         throw Error(parsedResponse.error || "Unknown error");
//     }

//     return parsedResponse;
// }

export async function chatApi(options: ChatRequest): Promise<Response> {
    const url = options.shouldStream ? apiBaseUrl+"/chat_stream" : apiBaseUrl+"/chat";
    const idToken =  localStorage.getItem("idToken")
    return await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer "+idToken
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });
    
}

// export async function projectConfig(): Promise<Response> {
//     const url = apiBaseUrl+"/project_config";
//     const idToken =  localStorage.getItem("idToken")
//     const response = await fetch(url, {
//         method: "GET",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": "Bearer "+idToken
//         }
//     });
    
//     const parsedResponse: Response = await response.json();

//     return parsedResponse
    
// }

export async function questionConfig(): Promise<questionResponse> {
    const url = apiBaseUrl+"/question_config";
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    
    const parsedResponse: questionResponse = await response.json();

    return parsedResponse
    
}

export async function knowledgeTableConfig() {
    const url = apiBaseUrl+"/content/data.csv";
    const idToken =  localStorage.getItem("idToken")
    const knowledgeTableResponse = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/octet_stream",
            "Authorization": "Bearer "+idToken
        }
    });  

    return knowledgeTableResponse;
    
}