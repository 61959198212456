import * as Msal from "@azure/msal-browser";
import { isAfter } from "date-fns";
import config from "../config";

const initMsal = () => {
  const msalConfig = {
    auth: {
      clientId: config.activeDirectory.clientId,
      authority: config.activeDirectory.authority,
      redirectUri: config.activeDirectory.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  };

  const msal = new Msal.PublicClientApplication(msalConfig);

  msal.initialize();

  // const initialize = await msal.initialize();

  return msal;
};
// await msal.initialize();

export const msal = initMsal();
// async () => {
//   const initializeMsal = initMsal();
//   await initializeMsal.initialize();
//   return initializeMsal;
// };
// await msal.initialize();
// hellow tteafds fdsa fs

const loginRequest = {
  scopes: [
    // "openid",
    // "profile",
    "User.Read"
  ],
};

export const loginRedirect = () => msal.loginRedirect(loginRequest);

export const getloginPopupResponse = async () =>
  await msal.loginPopup(loginRequest);

export const getAccount = () => msal.getAllAccounts()[0];

export const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("expiration");
  // sessionStorage.removeItem("accessToken");
  // sessionStorage.removeItem("idToken");
  // sessionStorage.removeItem("expiration");
  msal.logoutRedirect();
};

export const acquireTokenSilentAndSetLocal = async (accountInfo) => {
  const tokenRequest = {
    account: accountInfo,
    scopes: loginRequest.scopes,
    loginHint: accountInfo.idTokenClaims.preffered_username,
  };

  const res = await msal.acquireTokenSilent(tokenRequest);
  localStorage.setItem("accessToken", res.accessToken);
  localStorage.setItem("idToken", res.idToken);
  sessionStorage.setItem("token_cache", res.idToken);
  // sessionStorage.setItem("accessToken", res.accessToken);
  // sessionStorage.setItem("idToken", res.idToken);
};

export const allowedRoles = ["readAccess", "writeAccess", "adminAccess"];

export const isAuthenticated = () => {
  // await msal.initialize();
  const accountInfo = getAccount();
  const accessToken = localStorage.getItem("accessToken");
  const idToken = localStorage.getItem("idToken");
  // const accessToken = sessionStorage.getItem("accessToken");
  // const idToken = sessionStorage.getItem("idToken");
  const expiration = accountInfo?.idTokenClaims?.exp;
  const expiryDate = expiration ? new Date(expiration * 1000) : new Date(0);
  const now = new Date();
  const isExpired = !isAfter(expiryDate, now);

  if (accessToken && idToken && !isExpired) return true;
  return false;
};
