import { useState, useEffect } from "react";
import { usePapaParse } from "react-papaparse";
import { ArcTable } from "@arc-web/components/dist/components/table/react";
import { ArcButton } from "@arc-web/components/dist/components/button/react";
import { ArcContainer } from "@arc-web/components/dist/components/container/react";
import { ArcSpinner } from "@arc-web/components/dist/components/spinner/react";
import { _ } from "gridjs-react";
import parse from "html-react-parser";
import { useSearchParams } from "react-router-dom";
import { knowledgeTableConfig } from "../../api";
import axios from 'axios';

/* Component to read the csv using react-papaparse(https://react-papaparse.js.org/) which is 
based on papaparse(https://www.papaparse.com/) to read the csv from local directory.
*/
const CsvTable = ( props: { theme: any } ) => {

  // States to store headers, rows and path of csv provided in config
  // const [baseurl, setBaseUrl] = useState("import.meta.env.VITE_");
  const [headers, setHeaders] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [csvPath, setCsvPath] = useState("");
  const [searchParams, setSearchParams] = useSearchParams(window.location.search);
  const { readString } = usePapaParse();



  const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleCellClick = (e: any) => {
    const cellValue = e.detail[1].data;
    if (isValidHttpUrl(cellValue)) {
      window.open(cellValue, "_blank")?.focus();
    }
  };

  const createButton = (data: any[]) => {
    console.log(data);
    const header = data[0];
    const rows = data.slice(1);

    for (const row of rows) {
      for (const [index, cell] of row.entries()) {
        if (/<\/?[a-z][\s\S]*>/i.test(cell)) {
          const newCell = parse(cell);
          row[index] = _(newCell);
        } else if (isValidHttpUrl(cell)) {
          row[index] = _(
            <ArcButton
              value={cell}
              onClick={() => {
                window.open(cell, "_blank")?.focus();
              }}
            >
              Open
            </ArcButton>
          );
        }
      }
    }
    console.log("rows", rows);

    setHeaders(header);
    setRows(rows);
  };

  useEffect(() => {
    let baseURL = import.meta.env.VITE_API_BASE_URL
    // let path: string|null = searchParams.get("csvpath") ?? null;
    // if (path !== undefined && path !== null ) {
      let path = baseURL + '/knowledge_table';
      const idToken =  localStorage.getItem("idToken")
      // const table = knowledgeTableConfig();
      axios.get(path, {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'blob'  // Important: axios should return a Blob for PapaParse to be able to parse it
      })
      .then(response => {
        

        readString(response.data, {
          // @ts-ignore
          download: true,
          skipEmptyLines: true,
          dynamicTyping: true,
          complete: (results) => {
            createButton(results.data);
          }
        });
      })
      .catch(error => {
        console.error(error);
      });

      // readString(table, {
      //   // @ts-ignore
      //   download: true,
      //   skipEmptyLines: true,
      //   dynamicTyping: true,
      //   complete: (results) => {
      //     createButton(results.data);
      //   }
      // });

      setCsvPath(path);
    
  }, []);

  return (
    <ArcContainer theme={props.theme} fullscreen>
      {csvPath === "" ? (
        <div style={{ padding: "var(--arc-spacing-normal)"}}>
          Please Provide a CSV path using csvpath parameter e.g.
          ?csvpath=./data.csv
        </div>
      ) : headers.length > 0 && rows.length > 0 ? (
        <div
          style={{ padding: "var(--arc-spacing-normal)", overflow: "hidden" }}
        >
          <ArcTable
            columns={headers}
            data={rows}
            resizable
            sort
            search
            fixedHeader
            height="90vh"
            onArcCellClick={handleCellClick}
          ></ArcTable>
        </div>
      ) : (
        <ArcSpinner />
      )}
    </ArcContainer>
  );
};

export default CsvTable;
