import { Button, Theme, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// import SignInHelp from "./SignInHelp";
import {
  acquireTokenSilentAndSetLocal,
  getAccount,
  getloginPopupResponse,
  isAuthenticated,
} from "../../lib/auth";
import React, { useState } from "react";
const PREFIX = "SignIn";

const classes = {
  root: `${PREFIX}-root`,
  loginBtn: `${PREFIX}-loginBtn`,
  helpButton: `${PREFIX}-helpButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    height: "50vh",
    borderTop: `1px solid ${theme.palette.background.paper}`,
  },

  [`& .${classes.loginBtn}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "0.25rem 3rem",
    marginBottom: "0.5rem",
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  [`& .${classes.helpButton}`]: {
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      background: "none",
    },

    borderBottom: " 1px solid transparent",
    transition: "all .25s ease-in-out",
    "& p": {
      fontSize: "0.8rem",
    },
  },
}));

const SignIn = (props) => {
  const { setIsAuth } = props;

  const [open, setOpen] = useState(false);

  const submit = async () => {
    await getloginPopupResponse();
    if (getAccount()) {
      await acquireTokenSilentAndSetLocal(getAccount());
      setIsAuth(isAuthenticated());
    }
  };

  // console.log("REACT_APP_SAMPLE_KEY", process.env.REACT_APP_SAMPLE_KEY);

  return (
    <Root className={classes.root}>
      <Button
        onClick={submit}
        type="submit"
        className={classes.loginBtn}
        color="primary"      >
        <Typography>Login</Typography>
      </Button>
      {/* <Button
        className={classes.helpButton}
        onClick={() => {
        //   setOpen(!open);
        }}
      >
        <Typography>Having trouble to login?</Typography>
      </Button>
      <SignInHelp open={open} setOpen={setOpen} /> */}
    </Root>
  );
};

export default SignIn;
