import { Configuration } from "@azure/msal-browser";
//"homepage": "/00000000_custom_arup_compute/arup-compute/",



// console.log(
//   "REACT_APP_AAD_REDIRECT_URI",
//   process.env.REACT_APP_AAD_REDIRECT_URI,
// );

const config = {
  activeDirectory: {
    clientId:
      import.meta.env.VITE_APP_AAD_APP_ID ||
      "d59e53f4-83ad-447e-bdf4-d541b9e9f1f1",
    redirectUri:
    import.meta.env.VITE_APP_AAD_REDIRECT_URI || "/loggedin",
    authority: "https://login.microsoftonline.com/arup.onmicrosoft.com",
  },
};
// console.log("config", config);

export default config;
